import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/app/models/product.model';
import { ProductsService } from 'src/app/services/product.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  name = new FormControl("");
  description = new FormControl("");
  image: string ="";
  key = "";

  constructor(private productService: ProductsService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(
      params => {
        this.productService.getProductList().subscribe((data: any) => {
          data.forEach((element: Product) => {
            console.log(element.key + ":" + params['id']);
            if (element.key == params['id']) {
              this.key = params['id'];
              this.name.setValue(element.name);
              this.description.setValue(element.description);
              this.image = element.image;
            }
          });
        });
      })
  }

  handleUpload(event) {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {

      const file = inputNode.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result.toString();
      };

    }
  }
  deleteProduct() {
    this.productService.deleteProduct(this.key); 
  }

  submit() {
    this.productService.updateProduct(this.key, { name: this.name.value, description: this.description.value, image: this.image});
    this.router.navigate(["/admin"]);
  }

}
