<header>
    <div><span>Sie benötigen Bau-Geräte?</span></div><br>
    <p class="referenceText">Werfen Sie einen Blick auf unsere Lieblingsgeräte und besuchen Sie uns auf <a href="https://www.tutti.ch/de/seller?id=6422385174675301283" alt="Tutti">Tutti</a></p>
</header>
<div class="container">
    <div class="item" *ngFor="let product of products" (click)="openDialog(product)">
        <img [src]="product.image" [alt]="product.name">
        <div class="content">
            <h2>{{product.name}}</h2>
            <p class="description">
                {{product.description}}
            </p>
        </div>
    </div>
</div>
