import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/product.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {

  name = new FormControl("");
  description = new FormControl("");
  price = new FormControl("");
  amount = new FormControl("");
  image: string = "";


  constructor(private productService: ProductsService, private router: Router) { }

  ngOnInit(): void {
  }

  handleUpload(event) {
    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {

      const file = inputNode.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result.toString();
      };

    }
  }

  submit() {
    this.productService.createProduct({ name: this.name.value, description: this.description.value, image: this.image });
    this.router.navigate(["/admin"]);
  }


}
