import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'overflow'})
export class OverflowPipe  implements PipeTransform {
    transform(value: string, limit: number) {
        if (value.length < limit) {
            return value;
        }else {
            return value.substr(0,60) + "...";
        }
    }
  }