<div class="container">
    <img [src]="product.image" [alt]="product.name">
    <div class="content">
        <div class="left">
            <h2>{{product.name}}</h2>
            <p class="description">
                {{product.description}}
            </p>
        </div>
        <div class="right">
            <div class="container-right">
                <div class="actions">
                    <p>Kontaktiere uns: </p>
                    <a mat-button href="tel:0449998877">Telefon</a>
                    <a mat-button href="mailto:bauinventar.com">Email</a>
                </div>
            </div>
        </div>
    </div>
</div>