// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: "Bau Inventar",
  firebaseConfig: {
    apiKey: "AIzaSyBvFJX9En7HH1TM7fKY-Fi1ZjOD_jXQA3A",
    authDomain: "bau-inventar.firebaseapp.com",
    databaseURL: "https://bau-inventar-default-rtdb.firebaseio.com",
    projectId: "bau-inventar",
    storageBucket: "bau-inventar.appspot.com",
    messagingSenderId: "644177445288",
    appId: "1:644177445288:web:17105beab1520c00f95d9d",
    measurementId: "G-7D24350JNN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
