import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user.model'; // optional

import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<User>;

  loggedIn: boolean = false;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {}
  async signUp(email: string, password: string) {
    // await this.afAuth.createUserWithEmailAndPassword(email, password)
    //   .then(res => {
    //     this.loggedIn = true;
    //     console.log(res.user);
    //     localStorage.setItem('user', JSON.stringify(res.user));
    //     this.router.navigate(['/admin']);
    //   })
  }

  async signIn(email: string, password: string) {
    await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        this.loggedIn = true;
        console.log(res.user.uid);
        localStorage.setItem('user', JSON.stringify(res.user));
        this.router.navigate(['/admin']);
      });
  }

  logout() {
    this.afAuth.signOut();
    this.loggedIn = false;
    localStorage.removeItem('user');
    this.router.navigate(['/sign-in']);
  }
}
