import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  
  constructor(private auth: AuthService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.auth.loggedIn){
      var snackBar = this._snackBar.open("You are already logged in", "Logout", {duration: 5 * 1000});
      
      snackBar.onAction().subscribe((data: any) => {
        this.auth.logout();
      })
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),

  ]);


  signIn() { 
    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      console.log("Signed up");
      this.auth.signIn(this.emailFormControl.value, this.passwordFormControl.value);
    }
  }

}
