<div class="container">
    <h2>Kontaktieren Sie uns!</h2>
    <div class="address">
        <iframe class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.127487272462!2d8.74359751562301!3d47.3899453791709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479abcd59d2fff33%3A0x400addea457c779f!2sAm%20Wildbach%204%2C%208320%20Fehraltorf!5e0!3m2!1sde!2sch!4v1612109542285!5m2!1sde!2sch"
            width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0"></iframe>


    </div>
    <div class="actions">
        <a href="mailto:info@bauinventar.com" mat-raised-button color="primary">info@bauinventar.com</a>
        <a href="tel:+41796454940" mat-raised-button color="primary">+41 79 645 49 40</a>
    </div>
</div>