import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'price'})
export class PricePipe  implements PipeTransform {
    transform(value: string) {

        if (/^.*\..$/.test(value)) {
            return "CHF " + value + "0";
        } else {
            return "CHF " + value;
        }
    }
  }