import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  constructor(private auth: AuthService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    if (this.auth.loggedIn) {
      var snackBar = this._snackBar.open(
        'You are already logged in',
        'Logout',
        { duration: 5 * 1000 }
      );

      snackBar.onAction().subscribe((data: any) => {
        this.auth.logout();
      });
    }
  }

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);

  signUp() {
    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      console.log('Signed up');
      // this.auth.signUp(this.emailFormControl.value, this.passwordFormControl.value);
    }
  }
}
