import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '../models/product.model';
import { ProductsService } from '../services/product.service';
import { PricePipe } from '../pipes/price.pipe';
import { OverflowPipe} from '../pipes/overflow.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  products: Observable<SnapshotAction<Product>[]>;
  constructor(private productService: ProductsService, public dialog: MatDialog) { }

  ngOnInit(): void {

    this.productService.getProductList().subscribe((list: any) => {
      this.products = list;
    });
  }
  openDialog(product: Product) {
    const dialogRef = this.dialog.open(ProductDialogComponent,
      { data: { ...product }, panelClass: 'custom-dialog-container' });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
