import { Component, OnInit } from '@angular/core';
import { SnapshotAction } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { ProductsService } from 'src/app/services/product.service';

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent implements OnInit {

  displayedColumns = ['name', 'description', 'edit', 'delete'];
  products: Observable<SnapshotAction<Product>[]>;
  constructor(private productService: ProductsService, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.signIn("test@gmail.com", "123456").finally(() => {
      this.productService.getProductList().subscribe((list: any) => {
        this.products = list;
      });
    })

  }

  delete(key: string) {
    this.productService.deleteProduct(key);
  }

}
