<div>
    <h2>Anmelden</h2>
    <mat-form-field class="example-full-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl"
            placeholder="Ex. test@gmail.com">
        <mat-hint>Gib deine Email Adresse ein!</mat-hint>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Bitte gib eine korrekte Email Adresse ein. 
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
            Email wird <strong>benötigt</strong>
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Passwort</mat-label>
        <input type="password" matInput [formControl]="passwordFormControl" 
            placeholder="Ex. ********">
            <mat-hint>Gib dein Passwort ein!</mat-hint>
        <mat-error *ngIf="passwordFormControl.hasError('minlength') && !passwordFormControl.hasError('required')">
            Das Passwort muss mindestens 6 Buchstaben enthalten. 
        </mat-error>
        <mat-error *ngIf="passwordFormControl.hasError('required')">
            Passwort wird <strong>benötigt</strong>
        </mat-error>
    </mat-form-field>
    <br>
    <button (click)="signIn()" mat-raised-button color="primary">Anmelden</button>
    <button routerLink="/sign-up" mat-raised-button color="accent">Registrieren</button>
</div>