import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddProductComponent } from './admin/add-product/add-product.component';
import { AdminComponent } from './admin/admin.component';
import { ListProductComponent } from './admin/list-product/list-product.component';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditProductComponent } from './admin/edit-product/edit-product.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  
  {path: "sign-in", component: SignInComponent},
  {path: "sign-up", component: SignUpComponent},
  {path: "contact", component: ContactComponent},
  {path: "admin", component: AdminComponent, canActivate: [AuthGuard], children: [
    {path: "add-product", component: AddProductComponent},
    {path: "edit-product/:id", component: EditProductComponent},
    {path: "**", component: ListProductComponent},
  ]},
  {path: "**", component: DashboardComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
