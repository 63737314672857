<mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input type="text" matInput [formControl]="name">
    <mat-hint>Gebe einen Namen ein!</mat-hint>
</mat-form-field>
<br>
<mat-form-field class="example-full-width">
    <mat-label>Beschreibung</mat-label>
    <textarea matInput [formControl]="description"></textarea>
</mat-form-field>
<br>

<img [src]="image" alt="">
<button type="button" mat-raised-button (click)="fileInput.click()">Bild auswählen</button>
<input hidden (change)="handleUpload($event)" #fileInput type="file" id="file"><br>

<button mat-raised-button color="primary" (click)="submit()">Bearbeiten</button>
<button mat-raised-button color="warn" (click)="deleteProduct()">Löschen</button>

<button routerLink="/admin" mat-raised-button color="primary">Zurück zur Gerätliste</button>