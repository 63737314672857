import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Product } from '../models/product.model';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public product: Product) {}

  ngOnInit(): void {
    this.product
    console.log(this.product);
    console.log("moin");
  }

}
