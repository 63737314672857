<table mat-table [dataSource]="products">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Beschreibung </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>
    
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Bearbeiten </th>
        <td mat-cell *matCellDef="let element"><button [routerLink]="'/admin/edit-product/' + element.key" mat-raised-button color="accent">Bearbeiten</button></td>
    </ng-container>
    
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> Menge </th>
        <td mat-cell *matCellDef="let element"><button (click)="delete(element.key)" mat-raised-button color="warn">Löschen</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<button routerLink="/admin/add-product" mat-raised-button color="primary">Gerät hinzufügen</button>