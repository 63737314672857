import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { Product } from '../models/product.model';

 
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private dbPath = '/products';
 
  productsRef: AngularFireList<Product> = null;
 
  constructor(private db: AngularFireDatabase,) {
    this.productsRef = db.list(this.dbPath);
  }


  createProduct(prod: Product): void {
    this.productsRef.push(prod);
  }
 
  updateProduct(key: string, value: any): Promise<void> {
    return this.productsRef.update(key, value);
  }
 
  deleteProduct(key: string): Promise<void> {
    return this.productsRef.remove(key);
  }
  
 
  getProductList() {
    return this.productsRef.snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() })))
      )
  }
 
  deleteAllProducts(): Promise<void> {
    return this.productsRef.remove();
  }
}